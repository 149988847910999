<template>
  <div class="page">
    <div class="page__content">
      <div class="parameter">
        <div class="parameter__wrap--l">
          <img :src="goods.thumbnailUrl" class="parameter__wrap--bigImg" />
          <div class="parameter__wrap--carousel">
            <div @click="toArrow('left')" class="parameter__wrap--arrow">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="parameter__thumbnail">
              <div
                :style="{ transform: `translateX(-${80 * this.clickNum}px)` }"
                class="parameter__thumbnail--transform"
              >
                <img
                  :key="index"
                  :src="item"
                  @click="bigImg(item)"
                  class="parameter__thumbnail--img"
                  v-for="(item, index) in shopwindowImg"
                />
              </div>
            </div>
            <div @click="toArrow('right')" class="parameter__wrap--arrow">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="parameter__wrap--r" v-if="!isSample">
          <p class="parameter__wrap--title">
            <span class="common-i-buy-global" v-if="goods.type === 'GLOBAL'"
              >海淘</span
            >
            {{ goods.name }}
          </p>
          <div class="parameter__price">
            <p class="parameter__trade">
              <span class="parameter__trade--span">批发价：</span>
              <span class="parameter__trade--company">￥</span>
              {{ goods.price }}
            </p>
            <div class="parameter__retail">
              <p class="parameter__retail--p">
                建议零售价：¥
                <span class="parameter__retail--span">{{
                  goods.retailPrice
                }}</span>
              </p>
              <div class="parameter__retail--earn">
                赚¥{{ goods.discountAmount }}
              </div>
              <div class="parameter__retail--earn">
                毛利率：{{ grossProfitRate(goods.grossProfitRate) }}%
              </div>
            </div>
            <p class="parameter__purchase">
              活动大促价：{{ goods.minControlPrice || 0 }}
            </p>
            <p class="parameter__purchase">
              起订量：{{ goods.miniOrderQuantity }}
            </p>
          </div>
          <div class="parameter__specs">
            <div
              :key="index"
              class="parameter__specs--flex"
              v-for="(item, index) in skuObj"
            >
              <p class="parameter__specs--label">{{ item.label }}</p>
              <div class="parameter__specs--value">
                <div
                  :key="levelIndex"
                  @click="levelClick(levelItem, index, levelIndex)"
                  class="parameter__specs--item"
                  v-for="(levelItem, levelIndex) in item.list"
                >
                  <div class="parameter__specs--imgs" v-if="levelItem.showImg">
                    <img
                      :class="
                        levelItem.checked ? 'parameter__specs--active' : ''
                      "
                      :src="levelItem.showImg"
                      class="parameter__specs--img"
                    />
                    <img
                      class="parameter__specs--bgimg"
                      src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/goods-detail/select.png"
                      v-show="levelItem.checked"
                    />
                    <p class="parameter__specs--imghover">
                      {{ levelItem.name }}
                    </p>
                  </div>

                  <p
                    :class="levelItem.checked ? 'parameter__specs--active' : ''"
                    class="parameter__specs--text"
                    v-else
                  >
                    {{ levelItem.name }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="parameter__specs--flex"
              v-if="goods.singleRestrictionNum > 0 || goods.restrictionNum > 0"
            >
              <p class="parameter__specs--label">
                购买数量<span class="red__text"
                  >(限购{{
                    goods.singleRestrictionNum
                      ? goods.singleRestrictionNum
                      : goods.restrictionNum
                  }}件)</span
                >
              </p>
            </div>
            <div class="parameter__specs--flex">
              <p class="parameter__specs--label">数量</p>
              <div class="parameter__specs--value">
                <el-input-number
                  :max="goods.stock || 1"
                  :min="goods.miniOrderQuantity || 1"
                  @change="handleChange"
                  size="mini"
                  v-model="quantity"
                ></el-input-number>
              </div>
            </div>
          </div>
          <div class="parameter__btn">
            <el-button
              :loading="saveLoading"
              @click="addToCart"
              class="parameter__btn--save"
              type="primary"
              >加入采购车</el-button
            >
            <div
              class="parameter__download"
              @click="downloadMaterialZip"
              v-if="materialZip"
            >
              <img
                class="parameter__download__img"
                src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/download.png"
                alt=""
              />
              <p>素材</p>
            </div>
          </div>
        </div>
        <div class="parameter__wrap--r" v-if="isSample">
          <p class="parameter__wrap--title samplegoods_title">
            <span class="common-i-buy-global" v-if="goods.type === 'GLOBAL'"
              >海淘</span
            >{{ sampleGoods.name }}
          </p>
          <div class="parameter__price">
            <div class="parameter__trade">
              <span class="parameter__trade--span">样品领用价格：</span>
              <span class="parameter__trade--company">￥</span>
              {{ sampleGoods.price }}
            </div>
            <div class="parameter__retail">
              <p class="parameter__retail--p">
                预估批发价：¥
                <span class="parameter__retail--span">{{ goods.price }}</span>
              </p>
            </div>
            <div class="parameter__retail">
              <p class="parameter__retail--p">
                建议零售价：¥
                <span class="parameter__retail--span">{{
                  goods.retailPrice
                }}</span>
              </p>
            </div>
            <div class="parameter__retail">
              <p class="parameter__retail--p">
                活动大促价：¥
                <span class="parameter__retail--span">{{
                  goods.minControlPrice
                }}</span>
              </p>
            </div>
          </div>
          <h4 class="applyText">
            领取条件：每次限领一件,全部用户可领取（领取条件：每次限领一件,仅限新用户可领取）
          </h4>
          <div class="parameter__btn">
            <el-button
              @click="applyTrial"
              class="parameter__btn--save"
              type="primary"
              >申请试用</el-button
            >
            <div
              class="parameter__download"
              @click="downloadMaterialZip"
              v-if="materialZip"
            >
              <img
                class="parameter__download__img"
                src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/download.png"
                alt=""
              />
              <p>素材</p>
            </div>
          </div>
        </div>
      </div>
      <div class="details">
        <div class="details__collection">
          <img
            :src="collectionFlag ? collectedUrl : collectionUrl"
            @click="changeCollection"
            class="details__collectionimg"
          />
          <span>收藏商品</span>
          <span class="details__sales" v-if="goods.showSalesVolume === '1'"
            >销量：{{ goods.sales }}</span
          >
        </div>
        <div class="details__footer">
          <div class="details__l">
            <p class="details__l--topTitle">热销商品</p>
            <div :key="item.id" class="details__l--top" v-for="item in topList">
              <router-link :to="'/syoung/commodity/goods-detail/' + item.id">
                <img :src="item.thumbnailUrl" class="details__l--topImg" />
                <p class="details__l--topName">{{ item.name }}</p>
                <p class="details__l--topPrice">
                  <span class="details__l--topSymbol">￥</span>{{ item.price }}
                </p>
              </router-link>
            </div>
          </div>
          <div class="details__r">
            <el-tabs v-model="activeName" type="card" class="details__r--tabs">
              <el-tab-pane label="商品详情" name="first">
                <img
                  :key="index"
                  :src="item"
                  class="details__r--img"
                  v-for="(item, index) in detailImg"
                />
              </el-tab-pane>
              <el-tab-pane label="规格参数" name="second">
                <p
                  v-for="(item, index) in goods.commodityParamRelationVOList"
                  :key="index"
                  class="tabs__p"
                >
                  <span class="tabs__p--label">{{ item.paramName }}</span>
                  <span class="tabs__p--value">{{ item.paramValue }}</span>
                </p>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  fetchCommoditydetail,
  topCommodityList,
  getSampleInfoBySampleId
} from '@/api/commodity/goods-detail';
import { addToCart } from '@/api/commodity';
import eventReporter from '@/utils/event-reporter';
import {
  getListFavoriteCommodityByIds,
  cancelFavorites,
  createFavorites
} from '@/api/commodity/goods-collect';
export default {
  name: 'goodsDetail',
  data() {
    return {
      id: this.$route.params.id, // 商品id
      authCode: this.$route.query.authCode, // 商品id
      sampleId: this.$route.query.sampleId || '', // 小样id
      goods: {}, // 商品详情
      sampleGoods: {}, // 小样商详
      quantity: 1,
      skuid: '', // skuid
      skuObj: [], // 过滤sku
      clickObj: {}, // sku选中
      collectionFlag: false, // 是否收藏
      collectionUrl:
        'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/goods-detail/collection.png',
      collectedUrl:
        'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/goods-detail/collected.png',
      clickNum: 0, // 橱窗移动数
      shopwindowImg: [], // 橱窗图
      detailImg: [], // 详情图
      materialZip: '', // 素材下载包
      saveLoading: false,
      topList: [],
      activeName: 'first'
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    isSample() {
      // 是否小样
      return this.sampleId !== '';
    }
  },
  created() {
    console.log('this.$route', this.$route);
    this.init();
  },
  mounted() {},
  watch: {
    $route(to, from) {
      if (to.name === from.name && to.params.id !== from.params.id) {
        // 客服存在在商品详情里面跳商品详情页面的情况导致页面不刷新 这里做跳本页数据刷新处理
        this.id = to.params.id;
        this.skuObj = [];
        this.init();
      }
    }
  },
  methods: {
    // 下载素材
    downloadMaterialZip() {
      window.open(this.materialZip);
    },
    init() {
      this.fetchdetail(this.id);
      this.getCountStatisticsHot();
      this.topCommodityList();
      if (this.sampleId) {
        this.fetchSampleDetail();
      }
    },
    fetchSampleDetail() {
      getSampleInfoBySampleId(this.sampleId).then(res => {
        this.sampleGoods = res.data;
      });
    },
    async fetchdetail(id) {
      const { data } = await fetchCommoditydetail(id);
      const {
        brandType,
        name,
        thumbnailUrl,
        commodityMultimediaVO,
        minPrice,
        maxPrice,
        minRetailPrice,
        maxRetailPrice,
        minControlPrice,
        maxControlPrice,
        discountAmount,
        grossProfitRate,
        miniOrderQuantity = 1,
        skuVOS,
        specificationVOList,
        showSalesVolume,
        sales,
        type,
        brandName,
        brandGroup,
        singleRestrictionNum,
        restrictionNum,
        categoryIdList,
        commodityParamRelationVOList // 商品参数
      } = data;
      const {
        level1CommodityCategoryName,
        level2CommodityCategoryName,
        level3CommodityCategoryName
      } = data.commodityMainDataVO;
      const purchaseType = data.commodityExtensionVO.purchaseType;
      const price = data.skuVOlist[0].price;
      const categoryVOList = [data.categoryVOList[0].name];
      const goodsId = data.id;
      console.log(data);
      this.goods = {
        brandType,
        type,
        name,
        imgUrl: thumbnailUrl,
        thumbnailUrl,
        commodityMultimediaVO,
        price: minPrice === maxPrice ? minPrice : `${minPrice}-${maxPrice}`,
        retailPrice:
          minRetailPrice === maxRetailPrice
            ? minRetailPrice
            : `${minRetailPrice}-${maxRetailPrice}`,
        minControlPrice:
          minControlPrice === maxControlPrice
            ? minControlPrice
            : `${minControlPrice}-${maxControlPrice}`,
        discountAmount,
        grossProfitRate,
        miniOrderQuantity,
        skuVOS,
        specificationVOList,
        showSalesVolume,
        sales,
        singleRestrictionNum,
        restrictionNum,
        commodityParamRelationVOList
      };
      const param = {
        goodsId: goodsId,
        minPrice: price,
        commodityName: name,
        level1CommodityCategoryName: level1CommodityCategoryName,
        level2CommodityCategoryName: level2CommodityCategoryName,
        level3CommodityCategoryName: level3CommodityCategoryName,
        brandGroup: brandGroup,
        categoryIdList: categoryIdList,
        brandName: brandName,
        commodityGroup: categoryVOList
      };
      if (type === 'GLOBAL') {
        param.goodsType = '海淘';
      } else {
        param.goodsType = '大贸';
      }
      if (purchaseType === 'PURCHASE') {
        param.goodPurchaseType = '采销';
      } else if (purchaseType === 'DROP_SHIPPING') {
        param.goodPurchaseType = '一件代发';
      }
      this.$scReporter.track('ZG_GOODS_DETAIL', param);
      console.log('ZG_GOODS_DETAIL', JSON.stringify(param));
      eventReporter.trackGoodsDetail({
        // 来源埋点
        productId: data.id,
        name: data.name,
        type: data.type,
        price: data.minPrice // 取最小价
      }); // test
      if (commodityMultimediaVO) {
        // 缩略图 详情图
        for (const i of commodityMultimediaVO) {
          if (i.type === 'SHOPWINDOW') {
            this.shopwindowImg = JSON.parse(i.jsonValue);
          }
          if (i.type === 'DETAILS') {
            this.detailImg = JSON.parse(i.jsonValue);
          }
          if (i.type === 'MATERIAL_ZIP') {
            // 素材下载zip包
            this.materialZip = JSON.parse(i.jsonValue).url;
          }
        }
        this.goods.thumbnailUrl = this.shopwindowImg[0] || thumbnailUrl; // 主图
      }

      if (specificationVOList && specificationVOList.length > 0) {
        specificationVOList.forEach((item, index) => {
          this.skuObj.push({
            label: item.name,
            list: specificationVOList[index].specificationItemVOList.map(
              items => {
                return {
                  name: items.name,
                  showImg: items.showImg,
                  checked: false
                };
              }
            )
          });
        });
        // 起订量规格最小
        let quantityArr = [];
        for (const i of skuVOS) {
          quantityArr.push(i.miniOrderQuantity);
        }
        quantityArr = quantityArr.sort((a, b) => a - b);
        this.goods.miniOrderQuantity = quantityArr[0]; // 起订量
        this.quantity = quantityArr[0];
        console.log('this.skuObj', this.skuObj);
        // 规格选项只有一个默认选中
        this.skuObj.forEach((item, index) => {
          if (item.list.length === 1) {
            this.levelClick(item.list[0], index, 0);
          }
        });
      } else {
        [
          'price',
          'retailPrice',
          'minControlPrice',
          'discountAmount',
          'grossProfitRate',
          'miniOrderQuantity'
        ].forEach(item => {
          this.goods[item] = this.goods.skuVOS[0][item];
        });
        this.quantity = this.goods.skuVOS[0].miniOrderQuantity;
        this.goods.stock = this.goods.skuVOS[0].stock; // 库存
        this.skuid = this.goods.skuVOS[0].id; // skuId
      }

      // 是否收藏
      const { data: collectionFlag } = await getListFavoriteCommodityByIds({
        commodityIds: this.id,
        memberId: this.userInfo.memberId
      });
      if (collectionFlag.length > 0) {
        this.collectionFlag = true;
      }
    },
    applyTrial() {
      //...this.sampleGoods,
      const { commodityId, skuId, id, price, name } = this.sampleGoods;
      const { imgUrl, type } = this.goods;
      console.log(this.goods, 'this.goods');
      const data = [
        {
          commodityId,
          skuId,
          quantity: 1,
          sampleId: id,
          price,
          specCode: skuId,
          commodityName: name,
          imgUrl: imgUrl
        }
      ];
      sessionStorage.setItem('orderDetail', JSON.stringify(data));

      this.$router.push({
        path: '/syoung/checkout/list',
        query: { type, goodsType: 'sample', brandType: this.goods.brandType }
      });
    },
    levelClick(levelItem, index, levelIndex) {
      this.skuObj[index].list.forEach(item => {
        item.checked = false;
      });
      levelItem.checked = true;
      this.clickObj[index] = levelItem.name;
      if (index === 0) {
        this.goods.thumbnailUrl =
          this.goods.specificationVOList[index].specificationItemVOList[
            levelIndex
          ].showImg ||
          this.goods.defaultThumbnailUrl ||
          this.goods.thumbnailUrl;
      }
      console.log('this.goods.skuVOS', this.goods.skuVOS);
      if (
        Object.keys(this.clickObj).length ===
        this.goods.specificationVOList.length
      ) {
        const idx = this.goods.skuVOS.findIndex(item => {
          return (
            item.firstLevel === this.clickObj[0] &&
            (item.secondLevel ? item.secondLevel === this.clickObj[1] : true) &&
            (item.threeLevel ? item.threeLevel === this.clickObj[2] : true)
          );
        });
        console.log('idx', idx);
        [
          'price',
          'retailPrice',
          'minControlPrice',
          'discountAmount',
          'grossProfitRate',
          'miniOrderQuantity'
        ].forEach(item => {
          console.log(
            'this.goods[item]',
            this.goods[item],
            item,
            this.goods.skuVOS[idx][item]
          );
          this.goods[item] = this.goods.skuVOS[idx][item];
        });
        // this.goods.price = this.goods.skuVOS[idx].price; // 批发价
        // this.goods.retailPrice = this.goods.skuVOS[idx].retailPrice; // 建议零售价
        // this.goods.discountAmount = this.goods.skuVOS[idx].discountAmount; // 赚
        // this.goods.grossProfitRate = this.goods.skuVOS[idx].grossProfitRate; // 毛利率
        // this.goods.miniOrderQuantity = this.goods.skuVOS[idx].miniOrderQuantity; // 起订量
        this.quantity = this.goods.skuVOS[idx].miniOrderQuantity;
        this.goods.stock = this.goods.skuVOS[idx].stock; // 库存
        this.skuid = this.goods.skuVOS[idx].id; // skuId
      }
    },
    // 小图滑动
    toArrow(type) {
      const imgLength = this.shopwindowImg.length;
      const clickNum = imgLength - 3;
      if (clickNum < 1) {
        // console.log('小于4张不滑');
      } else {
        type === 'left'
          ? (this.clickNum = --this.clickNum)
          : (this.clickNum = ++this.clickNum);
        if (this.clickNum < 0) {
          this.clickNum = 0;
        }
        if (this.clickNum >= clickNum) {
          this.clickNum = clickNum;
        }
      }
    },
    bigImg(val) {
      this.goods.thumbnailUrl = val;
    },
    // 是否收藏
    changeCollection() {
      const request = this.collectionFlag ? cancelFavorites : createFavorites;
      request(this.id).then(res => {
        if (res.code === '0') {
          this.$message.success(
            this.collectionFlag ? '收藏成功' : '成功取消收藏'
          );
          this.getCountStatisticsHot();
        }
      });
      this.collectionFlag = !this.collectionFlag;
    },

    // 数量选择
    handleChange(value) {
      this.quantity = value;
    },
    // 加购
    addToCart() {
      if (this.skuid) {
        const data = {
          commodityId: this.id,
          authCode: this.authCode,
          quantity: this.quantity,
          skuId: this.skuid
        };
        this.saveLoading = true;
        console.log(data);
        addToCart(data)
          .then(res => {
            // 加入购物车埋点
            eventReporter.trackClick(this.$route, {
              name: 'zg_add_goods_click_btn',
              aliasName: '加入购物车',
              query: this.id,
              event_source: 'goods'
            });
            this.$router.push('/syoung/car/list');
          })
          .finally(() => {
            this.saveLoading = false;
          });
      } else {
        this.$message.warning('请选择商品');
      }
    },
    grossProfitRate(val) {
      const percent = (val * 100).toFixed(0);
      return percent;
    },
    // top5
    topCommodityList() {
      topCommodityList(5).then(res => {
        this.topList = res.data;
      });
    },
    ...mapActions(['getCountStatisticsHot'])
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.page {
  background: #fff;
  &__content {
    padding: 0 20px;
    width: 100%;
  }
}
.parameter {
  display: flex;
  justify-content: flex-start;
  &__wrap {
    &--l {
      width: 358px;
      margin-right: 20px;
    }
    &--bigImg {
      width: 358px;
      height: 358px;
      margin-bottom: 10px;
    }
    &--carousel {
      width: 358px;
      height: 70px;
      display: flex;
    }
    &--r {
      flex: 1;
    }
    &--title {
      color: #222;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 12px;
    }
    &--arrow {
      width: 30px;
      height: 70px;
      border: 1px solid #999;
      display: inline-block;
      text-align: center;
      line-height: 70px;
      .el-icon-arrow-left {
        font-size: 21px;
        color: #999;
      }
      .el-icon-arrow-right {
        font-size: 21px;
        color: #999;
      }
    }
  }
  &__thumbnail {
    width: 298px;
    padding: 0 10px;
    overflow: hidden;
    &--transform {
      transition: all 0.3s;
      display: flex;
      height: 70px;
      min-width: 298px;
      max-width: 800px;
    }
    &--img {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      flex-shrink: 0;
      border: 1px solid #999;
    }
  }
  &__price {
    background: #fff7f8;
    padding: 14px 10px;
    margin-bottom: 20px;
  }
  &__trade {
    font-size: 20px;
    color: #ab0033;
    margin: 0 0 10px;
    &--span {
      color: #666;
      font-size: 14px;
    }
    &--company {
      font-size: 12px;
    }
  }
  &__retail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    &--p {
      color: #666;
      font-size: 14px;
    }
    &--span {
      font-size: 18px;
      margin-right: 20px;
    }
    &--earn {
      font-size: 14px;
      line-height: 24px;
      color: #ab0033;
      padding: 0 6px;
      border: 1px solid #ab0033;
      border-radius: 4px;
      background: #fff;
      margin-right: 10px;
    }
  }
  &__purchase {
    color: #666;
    font-size: 14px;
    margin-bottom: 0;
  }
  &__specs {
    padding-top: 20px;
    border-top: 1px dotted #ececec;
    &--flex {
      display: flex;
      margin-bottom: 14px;
    }
    &--label {
      min-width: 30px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #666;
      margin: 0 24px 10px 0;
    }
    &--value {
      display: flex;
      flex-wrap: wrap;
    }
    &--item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
    }
    &--imgs {
      position: relative;
      margin-right: 10px;
    }
    &--bgimg {
      position: absolute;
      right: 0;
      bottom: 4px;
    }
    &--img {
      width: 46px;
      height: 46px;
      &:hover {
        border: 1px solid #ab0033;
      }
    }
    &--imghover {
      position: absolute;
      left: 0;
      bottom: -12px;
      font-size: 12px;
      height: 14px;
      line-height: 14px;
      padding: 0 2px;
      border: 1px solid #999;
      color: #999;
      margin: 0;
      display: none;
    }
    &--text {
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      margin: 0 10px 10px 0;
      border: 1px solid #999;
      color: #999;
    }
    &--active {
      color: #ab0033;
      border: 1px solid #ab0033;
      background: url("https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/goods-detail/select.png")
        bottom right no-repeat;
    }
  }
  &__btn {
    display: flex;
    &--save {
      width: 200px;
      height: 48px;
      background: #ab0033;
      border: none;
      font-size: 18px;
    }
  }
  &__download {
    display: flex;
    flex-direction: column;
    font-size: 22rpx;
    font-weight: 500;
    color: #2c2c2c;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
    &__img {
      width: 25px;
    }
  }
}
.details {
  background: #fff;
  overflow: hidden;
  margin-top: 20px;
  padding-bottom: 36px;
  &__footer {
    display: flex;
  }
  &__collection {
    width: 100%;
    display: flex;
    height: 24px;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    color: #222;
    margin-bottom: 40px;
  }
  &__collectionimg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  &__sales {
    margin-left: 40px;
  }
  &__l {
    width: 212px;
    border: 1px solid #efefef;
    margin-right: 12px;
    &--topTitle {
      text-align: center;
      font-size: 18px;
      color: #000;
      height: 66px;
      line-height: 66px;
      margin: 0;
      border-bottom: 1px solid #efefef;
    }
    &--top {
      width: 192px;
      margin: 18px auto 0;
      text-align: center;
      border-bottom: 1px solid #efefef;
      &:last-of-type {
        border: none;
      }
    }
    &--topImg {
      width: 182px;
      height: 182px;
    }
    &--topName {
      color: #414141;
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      margin-top: 15px;
    }
    &--topPrice {
      margin: 20px 0 38px;
      color: #e60000;
      font-size: 19px;
    }
    &--topSymbol {
      font-size: 14px;
    }
  }
  &__r {
    border: 1px solid #efefef;
    flex: 1;
    &--title {
      height: 38px;
      line-height: 38px;
      font-size: 20px;
      color: #fff;
      margin: 0;
      margin-bottom: 8px;
      background: #d80a30;
      padding-left: 42px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 20px;
        top: 13px;
        background: #fff;
      }
    }
    &--img {
      width: 100%;
      display: block;
    }
    &--tabs {
      /deep/.el-tabs__header {
        margin: 0;
      }
      /deep/.el-tabs__nav-scroll {
        background: #ab0033;
        .el-tabs__nav {
          border: none;
        }
        .el-tabs__item {
          color: #fff;
          border: none;
          &:hover {
            font-weight: bold;
          }
        }
        .el-tabs__item.is-active {
          color: #fff;
          border: none;
        }
      }
    }
  }
}

.red__text {
  color: #ab0033;
}

.parameter__specs--imgs {
  &:hover {
    .parameter__specs--imghover {
      display: block;
    }
  }
}
.tabs__p {
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  line-height: 16px;
  &--label {
    color: #999;
    margin-right: 40px;
    width: 80px;
    display: inline-block;
  }
  &--value {
    color: #666;
    display: inline-block;
  }
}
.applyText {
  margin-bottom: 10px;
}
.samplegoods_title {
  margin-bottom: 10px;
}
</style>

import { withExtTenantIdRequest } from '@/utils/request';

// 获取商品类型
export function fetchListOnSaleAndNotHidden(data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodityEs/listOnSaleAndNotHidden',
    method: 'post',
    data
  });
}

// 移动端-获取商品详情
export function fetchCommoditydetail(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/tradeCenterCommodity/detail?id=${id}`,
    method: 'get'
  });
}

// 移动端-根据商品id获取营销活动
export function getActivityListByCommodityId(id) {
  return withExtTenantIdRequest({
    url: `/marketing/api/activity/listActivityRuleForCommodity?commodityId=${id}`,
    method: 'post'
  });
}

export function topCommodityList(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/orderStatistics/topCommodityList?top=${data}`,
    method: 'get'
  });
}

// 小样商品商详
export function getSampleInfoBySampleId(sampleId) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/sampleActivity/getSampleInfoBySampleId?sampleId=${sampleId}`,
    method: 'get'
  });
}
